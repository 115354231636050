import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'

const success = () => (
    <React.Fragment>
        <SEO title="success" />
        <Layout>
            <h2>Success</h2>
            <p>Thank you for contacting me. I will get back to you regarding your message as soon as possible.</p>
        </Layout>
    </React.Fragment> 
)

export default success;